import * as React from 'react';
import { Router } from '@reach/router';
// import Articles from '../components/Articles/Articles';
// import Article from '../components/Articles/article';
import loadable from '@loadable/component'

const Articles = loadable(() => import('../components/Articles/Articles'));
const Article = loadable(() => import('../components/Articles/article'));

const TheBlog = () => (
  <Router>
    <Articles path="/actualites" />
    <Article path="/actualites/:id/:title" />
  </Router>
);

export default TheBlog;